<template>
  <div class="container-fluid">
    <div v-if="loaded">
      <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
        <h4 id="top">Reports Dashboard</h4>
      </div>

      <div class="row card search-card">
        <div class="rainbow-background"></div>
        <p class="instructions">To see Reports select a 'Week Starting' and 'Week Ending'. Additionally, you may select <b><em>one</em></b> filter.</p>
        <div class="col-md-12 row">
          <div class="col-md-3 align-left">
            <label for="week_starting">Week Starting: </label>
            <datepicker id="week_starting" input-class="date-picker" :disabled-dates="this.state.disabledStartDates" :resetOnOptionsChange="true" label="week_start_date" v-model="selectedStartWeek" placeholder="Select Week Start" format="MM-dd-yyyy" ></datepicker>
          </div>
          <div class="col-md-3 align-left">
            <label for="week_ending">Week Ending: </label>
            <datepicker id="week_ending" input-class="date-picker" :disabled-dates="this.state.disabledEndDates" :resetOnOptionsChange="true" label="week_end_date" v-model="selectedEndWeek" placeholder="Select Week End" format="MM-dd-yyyy" ></datepicker>
          </div>
          <div class="col-md-3 align-left">
            <label for="coach" class="align-left">Coach: </label>
            <v-select id="coach" :disabled="filtersDisabled" :options="coachResults" :clearable="false" :clearSearchOnSelect="false" :resetOnOptionsChange="true" label="full_name" v-model="selectedCoach" placeholder="Select a Coach"/>
          </div>
          <div class="col-md-3 align-left">
            <label for="topic" class="align-left">Audience: </label>
            <v-select id="topic" :disabled="filtersDisabled" :options="topicResults" :clearable="false" :clearSearchOnSelect="false" :resetOnOptionsChange="true" label="title" v-model="selectedTopic" placeholder="Select an Audience"/>
          </div>
        </div>

        <br>

        <div class="col-md-12 row">
          <div class="col-md-3 align-left">
            <label for="role" class="align-left">Trainee Role: </label>
            <v-select id="role" :disabled="filtersDisabled" :options="roleResults" :clearable="false" :clearSearchOnSelect="false" :resetOnOptionsChange="true" label="title" v-model="selectedRole" placeholder="Select a Role"></v-select>
          </div>
          <div class="col-md-3 align-left">
            <label for="market_area">Market Area: </label>
            <v-select id="market_area" :disabled="filtersDisabled" :options="marketAreaResults" :clearable="false" :clearSearchOnSelect="false" :resetOnOptionsChange="true" label="name" v-model="selectedMarketArea" placeholder="Select a Market Area"></v-select>
          </div>
          <div class="col-md-3 align-left">
            <label for="region">Region: </label>
            <v-select id="region" :disabled="filtersDisabled" :options="regionResults" :clearable="false" :clearSearchOnSelect="false" :resetOnOptionsChange="true" label="name" v-model="selectedRegion" placeholder="Select a Region"></v-select>
          </div>
          <div class="col-md-3 align-left">
            <label for="phase">Phase: </label>
            <v-select id="phase" :disabled="filtersDisabled" :options="phaseResults" :clearable="false" :clearSearchOnSelect="false" :resetOnOptionsChange="true" label="title" v-model="selectedPhase" placeholder="Select a Phase"></v-select>
          </div>
        </div>

        <br>

        <div class="col-md-12 row">
          <div class="col-md-3 align-left">
              <button class="btn btn-danger" v-on:click="clearFilters">Clear Filters</button>
              <download-csv
                v-if="reportsLoaded"
                class="btn btn-success ml-1"
                :data    = "parsedTraineeReports"
                :name    = "'trainee-report(' + selectedStartWeek + ' - ' + selectedEndWeek + ').csv'"
                :labels  = downloadDatabaseLabels
                :fields  = downloadDatabaseFields
              >
                Download Database
              </download-csv>
          </div>
        </div>

      </div>

      <br>

      <h3 class="mt-5" v-if="loadingText">
        Loading Reports...
      </h3>

      <div v-if="reportsLoaded" class="row">
        <div class="col-md-12 card float-left text-left">
          <div class="row">
            <div class="float-left col-6">
              <h5 class="ml-3 pt-3">Activity Summary Report</h5>
            </div>
            <div class="float-right col-6 pt-2" v-if="Object.keys(activityReports).length > 0">
              <download-csv
                class="btn btn-success mr-1 float-right"
                :data    = "activityReportsArray"
                :name    = "'activity-report(' + selectedStartWeek + ' - ' + selectedEndWeek + ').csv'"
                :labels  = activityReportLabels
                :fields  = activityReportFields
              >
                Export Report
              </download-csv>
            </div>
          </div>
          <div class="table-responsive p-3">
            <table class="card-table table">
              <thead>
              <tr>
                <th class="w-75">Activity</th>
                <th>Days</th>
                <th>%</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(value, key) in activityReports" :key="key">
                <td class="w-75">{{ key }}</td>
                <td>{{ value.hours }}</td>
                <td>{{ value.percentage }}%</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <br>

      <div v-if="reportsLoaded" class="row">
        <div class="col-md-12 card float-left text-left">
          <div class="row">
            <div class="float-left col-6">
              <h5 class="ml-3 pt-3">Trainee Role Summary Report</h5>
            </div>
            <div class="float-right col-6 pt-2" v-if="Object.keys(roleReports).length > 0">
              <download-csv
                class="btn btn-success mr-1 float-right"
                :data    = "roleReportsArray"
                :name    = "'user-role-report(' + selectedStartWeek + ' - ' + selectedEndWeek + ').csv'"
                :labels  = roleReportLabels
                :fields  = roleReportFields
              >
                Export Report
              </download-csv>
            </div>
          </div>
          <div class="table-responsive p-3">
            <table class="card-table table">
              <thead>
              <tr>
                <th class="w-75">Trainee Role</th>
                <th>Days</th>
                <th>%</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(value, key) in roleReports" :key="key">
                <td class="w-75">{{ key }}</td>
                <td>{{ value.hours }}</td>
                <td>{{ value.percentage }}%</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <br>

      <div v-if="reportsLoaded" class="row">
        <div class="col-md-12 card float-left text-left">
          <div class="row">
            <div class="float-left col-6">
              <h5 class="ml-3 pt-3">Trainee Summary Report</h5>
            </div>
            <div class="float-right col-6 pt-2" v-if="traineeReports.length > 0">
              <download-csv
                class="btn btn-success mr-1 float-right"
                :data    = "parsedTraineeReports"
                :name    = "'trainee-report(' + selectedStartWeek + ' - ' + selectedEndWeek + ').csv'"
                :labels  = traineeReportLabels
                :fields  = traineeReportFields
              >
                Export Report
              </download-csv>
            </div>
          </div>
          <div class="table-responsive p-3">
            <table class="card-table table">
              <thead>
              <tr>
                <th>Date</th>
                <th>Name of Trainee</th>
                <th>Role of Trainee</th>
                <th>Region</th>
                <th>Market Area</th>
                <th>Coach</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(trainee, index) in traineeReports" :key="index" :id="trainee.id">
                <td>{{ trainee.time_card_activity_date }}</td>
                <td>{{ trainee.user.first_name + " " + trainee.user.last_name }}</td>
                <td>{{ trainee.user.trainee_role ? trainee.user.trainee_role.title : "N/A" }}</td>
                <td>{{ trainee.user.region ? trainee.user.region.name : "N/A" }}</td>
                <td>{{ trainee.user.region ? trainee.user.region.market_area.name : "N/A" }}</td>
                <td>{{ trainee.time_card_activity.time_card.user.first_name + " " + trainee.time_card_activity.time_card.user.last_name}}</td>
              </tr>
              <tr>
                <td>TOTAL</td>
                <td>{{ data_length }}</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div v-else>
      <loading-overlay></loading-overlay>
    </div>

    <button v-on:click="scrollToTop" id="topButton" type="button" class="btn btn-success">TOP</button>

  </div>
</template>

<script>
import { TimeCardService } from '@/common/services/time_card.service';
import { TimeCardActivityService } from '@/common/services/time_card_activity.service';
import { TimeCardActivityUserService } from '@/common/services/time_card_activity_user.service';
import { PhaseService } from '@/common/services/phase.service';
import { TraineeRoleService } from '@/common/services/trainee_role.service';
import { RegionService } from '@/common/services/region.service';
import { UserService } from '@/common/services/user.service';
import { FilterService } from '@/common/services/filter.service';
import { MarketAreaService } from '@/common/services/market_area.service';
import { TopicService } from '../../common/services/topic.service';
import HelperMethods from '@/common/mixins/helper_methods';
import LoadingOverlay from '@/common/components/loading-overlay.vue';
import Datepicker from 'vuejs-datepicker';


export default {
  name: 'ReportsIndex',
  mixins: [HelperMethods],
  components: {
    LoadingOverlay,
    Datepicker,
  },
  data() {
    return {
      searchedCoachName: null,
      coachResults: [],
      weekStartResults: [],
      weekEndResults: [],
      roleResults: [],
      topicResults: [],
      marketAreaResults: [],
      phaseResults: [],
      regionResults: [],
      selectedCoach: [],
      selectedStartWeek: null,
      selectedEndWeek: null,
      parsedStartWeek: null,
      parsedEndWeek: null,
      selectedRole: [],
      selectedMarketArea: [],
      selectedPhase: [],
      selectedRegion: [],
      selectedTopic: [],
      loaded: false,
      reportsLoaded: false,
      loadingText: false,
      // defaults
      activityReports: null,
      activityReportsArray: [],
      roleReports: null,
      roleReportsArray: [],
      traineeReports: null,
      parsedTraineeReports: null,
      coach_id: null,
      topic_id: null,
      trainee_role_id: null,
      region_id: null,
      market_area_id: null,
      phase_id: null,
      export: false,
      data_length: 0,
      days: ['day_one', 'day_two', 'day_three', 'day_four', 'day_five', 'day_six', 'day_seven'],
      filtersDisabled: true,
      state: {
        disabledStartDates: {
          days: [1, 2, 3, 4, 5, 6],
        },
        disabledEndDates: {
          days: [0, 1, 2, 3, 4, 5],
        },
      },
      // Activity Report Data
      activityReportLabels: {
        activity: 'Activity',
        topics: 'Audiences',
        hours: 'Total Days',
        percentage: 'Percentage',
      },
      activityReportFields: [
        'activity',
        'topics',
        'hours',
        'percentage',
      ],
      roleReportLabels: {
        role: 'Trainee Role',
        hours: 'Total Days',
        percentage: 'Percentage',
      },
      roleReportFields: [
        'role',
        'hours',
        'percentage',
      ],
      // Trainee Report Data
      traineeReportLabels: {
        time_card_activity_date: 'Date',
        time_card_activity_user_first_name: 'Trainee First Name',
        time_card_activity_user_last_name: 'Trainee Last Name',
        time_card_activity_trainee_role_title: 'Role of Trainee',
        time_card_activity_user_region_name: 'Region',
        time_card_activity_user_region_market_area_name: 'Market Area',
        coach_first_name: 'Coach First Name',
        coach_last_name: 'Coach Last Name',
      },
      traineeReportFields: [
        'time_card_activity_date',
        'time_card_activity_user_first_name',
        'time_card_activity_user_last_name',
        'time_card_activity_trainee_role_title',
        'time_card_activity_user_region_name',
        'time_card_activity_user_region_market_area_name',
        'coach_first_name',
        'coach_last_name',
      ],
      // Download Database Data
      downloadDatabaseLabels: {
        time_card_activity_id: 'Activity Report Activity ID',
        time_card_activity_time_card_id: 'Activity Report Activity Activity Report ID',
        time_card_activity_activity_id: 'Activity Report Activity Activity ID',
        time_card_activity_day_one: 'Activity Report Activity Day One',
        time_card_activity_day_two: 'Activity Report Activity Day Two',
        time_card_activity_day_three: 'Activity Report Activity Day Three',
        time_card_activity_day_four: 'Activity Report Activity Day Four',
        time_card_activity_day_five: 'Activity Report Activity Day Five',
        time_card_activity_day_six: 'Activity Report Activity Day Six',
        time_card_activity_day_seven: 'Activity Report Activity Day Seven',
        time_card_activity_comment: 'Activity Report Activity Comment',
        time_card_activity_created: 'Activity Report Activity Created',
        time_card_id: 'Activity Report ID',
        time_card_week_start_date: 'Activity Report Week Start Date',
        time_card_user_id: 'Activity Report User ID',
        time_card_status: 'Activity Report Status',
        time_card_rejection_note: 'Activity Report Rejection Note',
        coach_id: 'Coach ID',
        coach_email: 'Coach Email',
        coach_first_name: 'Coach First Name',
        coach_last_name: 'Coach Last Name',
        coach_role: 'Coach Role',
        coach_disabled: 'Coach Disabled',
        coach_trainee_role_id: 'Coach Trainee Role ID',
        coach_region_id: 'Coach Region ID',
        coach_phase_id: 'Coach Phase ID',
        time_card_activity_activity_title: 'Activity Report Activity Activity Title',
        time_card_activity_topics: 'Activity Report Activity Topics',
        time_card_activity_user_id: 'Activity Report Activity User ID',
        time_card_activity_user_email: 'Activity Report Activity User Email',
        time_card_activity_user_first_name: 'Activity Report Activity User First Name',
        time_card_activity_user_last_name: 'Activity Report Activity User Last Name',
        time_card_activity_trainee_role: 'Activity Report Activity Trainee Role',
        time_card_activity_user_disabled: 'Activity Report Activity User Disabled',
        time_card_activity_user_trainee_role_id: 'Activity Report Activity User Trainee Role ID',
        time_card_activity_user_region_id: 'Activity Report Activity User Region ID',
        time_card_activity_user_phase_id: 'Activity Report Activity User Phase ID',
        time_card_activity_user_phase_title: 'Activity Report Activity User Phase Title',
        time_card_activity_trainee_role_id: 'Activity Report Activity Trainee Role ID',
        time_card_activity_trainee_role_title: 'Activity Report Activity Trainee Role Title',
        time_card_activity_user_region_name: 'Activity Report Activity User Region Name',
        time_card_activity_user_region_market_area_id: 'Activity Report Activity User Region Market Area ID',
        time_card_activity_user_region_market_area_name: 'Activity Report Activity User Region Market Area Name',
        time_card_activity_user_region_region_code: 'Activity Report Activity User Region Region Code',
      },
      downloadDatabaseFields: [
        'time_card_activity_id',
        'time_card_activity_time_card_id',
        'time_card_activity_activity_id',
        'time_card_activity_day_one',
        'time_card_activity_day_two',
        'time_card_activity_day_three',
        'time_card_activity_day_four',
        'time_card_activity_day_five',
        'time_card_activity_day_six',
        'time_card_activity_day_seven',
        'time_card_activity_comment',
        'time_card_activity_created',
        'time_card_id',
        'time_card_week_start_date',
        'time_card_user_id',
        'time_card_status',
        'time_card_rejection_note',
        'coach_id',
        'coach_email',
        'coach_first_name',
        'coach_last_name',
        'coach_role',
        'coach_disabled',
        'coach_trainee_role_id',
        'coach_region_id',
        'coach_phase_id',
        'time_card_activity_activity_title',
        'time_card_activity_topics',
        'time_card_activity_user_id',
        'time_card_activity_user_email',
        'time_card_activity_user_first_name',
        'time_card_activity_user_last_name',
        'time_card_activity_trainee_role',
        'time_card_activity_user_disabled',
        'time_card_activity_user_trainee_role_id',
        'time_card_activity_user_region_id',
        'time_card_activity_user_phase_id',
        'time_card_activity_user_phase_title',
        'time_card_activity_trainee_role_id',
        'time_card_activity_trainee_role_title',
        'time_card_activity_user_region_name',
        'time_card_activity_user_region_market_area_id',
        'time_card_activity_user_region_market_area_name',
        'time_card_activity_user_region_region_code',
      ],
    };
  },
  created() {
    this.getActivityReport()
      .then((response) => {
        this.activityReports = response.data;
        return this.getUserRoleReport();
      })
      .then((response) => {
        this.roleReports = response.data;
        return this.getTraineeReport();
      })
      .then((response) => {
        this.traineeReports = response.data;
        this.getAllFields();
      });

    window.addEventListener('scroll', this.scrollCheck);
  },
  methods: {
    scrollCheck() {
      if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
        document.getElementById('topButton').style.display = 'block';
      } else {
        document.getElementById('topButton').style.display = 'none';
      }
    },
    updateReports() {
      let keys = [];
      if (this.selectedStartWeek !== null && this.selectedEndWeek !== null) {
        this.parsedEndWeek = this.selectedEndWeek;
        this.parsedStartWeek = this.selectedStartWeek;

        if (typeof this.parsedStartWeek !== 'string') this.parsedStartWeek = this.getDateString(this.parsedStartWeek);
        if (typeof this.parsedEndWeek !== 'string') this.parsedEndWeek = this.getDateString(this.parsedEndWeek);

        this.reportsLoaded = false;
        this.loadingText = true;
        this.getActivityReport()
          .then((response) => {
            this.activityReports = response.data;
            this.activityReportsArray = Object.values(this.activityReports);
            keys = Object.keys(this.activityReports);

            for (let i = 0; i < this.activityReportsArray.length; i += 1) {
              this.activityReportsArray[i].activity = keys[i];
            }

            this.log('updateReports -> getActivityReport', response.data);
            return this.getUserRoleReport();
          })
          .then((response) => {
            this.roleReports = response.data;
            this.roleReportsArray = Object.values(this.roleReports);
            keys = Object.keys(this.roleReports);

            for (let i = 0; i < this.roleReportsArray.length; i += 1) {
              this.roleReportsArray[i].role = keys[i];
            }

            this.log('updateReports -> getUserRoleReport', response.data);
            return this.getTraineeReport();
          })
          .then((response) => {
            this.data_length = 0;
            this.traineeReports = [];
            Object.keys(response.data).forEach((trainee) => {
              Object.keys(this.days).forEach((day) => {
                if (response.data[trainee].time_card_activity[this.days[day]] !== null && response.data[trainee].time_card_activity[this.days[day]] !== 'Non Working') {
                  response.data[trainee].time_card_activity_date = this.formattedDate(response.data[trainee].time_card_activity.time_card.week_start_date, this.days.indexOf(this.days[day]) + 1);
                  this.traineeReports.push(Object.assign({}, response.data[trainee]));
                  this.data_length += 1;
                }
              });
            });
            this.log('updateReports -> getTraineeReport', response.data);
            this.export = true;
            return this.getTraineeReport();
          })
          .then((response) => {
            this.log('updateReports -> getTraineeReport -> parsed', response.data);
            this.parsedTraineeReports = [];
            Object.keys(response.data).forEach((trainee) => {
              Object.keys(this.days).forEach((day) => {
                if (response.data[trainee][`time_card_activity_${this.days[day]}`] !== null && response.data[trainee][`time_card_activity_${this.days[day]}`] !== 'Non Working') {
                  response.data[trainee].time_card_activity_date = this.formattedDate(response.data[trainee].time_card_week_start_date, this.days.indexOf(this.days[day]) + 1);
                  this.parsedTraineeReports.push(Object.assign({}, response.data[trainee]));
                }
              });
            });
            this.export = false;
            this.reportsLoaded = true;
            this.loadingText = false;
          });
      }
    },
    getAllFields() {
      Promise.all([this.getCoaches(), this.getRoles(), this.getPhases(), this.getMarketAreas(), this.getRegions(), this.getWeeks(), this.getTopics(), this.getActivityReport(), this.getUserRoleReport(), this.getTraineeReport()])
        .then((response) => {
          const [coaches, roles, phases, marketAreas, regions, weeks, topics, activityReport, userRoleReport, traineeReport] = response.map(res => res.data);
          this.coachResults = coaches;
          this.roleResults = roles;
          this.phaseResults = phases;
          this.marketAreaResults = marketAreas;
          this.regionResults = regions;
          this.topicResults = topics;
          this.activityReports = activityReport;
          this.roleReports = userRoleReport;
          this.traineeReports = traineeReport;
          this.weekStartResults = weeks[0].determine_all_start_dates.sort((a, b) => new Date(b).getTime() - new Date(a).getTime());
          this.weekEndResults = weeks[0].determine_all_end_dates.sort((a, b) => new Date(b).getTime() - new Date(a).getTime());
          this.loaded = true;
        });
    },
    getCoaches() {
      this.filters = FilterService.defaultData();
      this.filters.role_end = 'Coach';
      const params = FilterService.build({ page: 1, per_page: 3000 }, this.filters);
      return UserService.query(params);
    },
    getRoles() {
      this.filters = FilterService.defaultData();
      const params = FilterService.build({ page: 1, per_page: 3000 }, this.filters);
      return TraineeRoleService.query(params);
    },
    getPhases() {
      this.filters = FilterService.defaultData();
      const params = FilterService.build({ page: 1, per_page: 3000 }, this.filters);
      return PhaseService.query(params);
    },
    getMarketAreas() {
      this.filters = FilterService.defaultData();
      const params = FilterService.build({ page: 1, per_page: 3000 }, this.filters);
      return MarketAreaService.query(params);
    },
    getRegions() {
      this.filters = FilterService.defaultData();
      const params = FilterService.build({ page: 1, per_page: 3000 }, this.filters);
      return RegionService.query(params);
    },
    getWeeks() {
      this.filters = FilterService.defaultData();
      const params = FilterService.build({ page: 1, per_page: 3000 }, this.filters);
      return TimeCardService.query(params);
    },
    getTopics() {
      this.filters = FilterService.defaultData();
      const params = FilterService.build({ page: 1, per_page: 3000 }, this.filters);
      return TopicService.query(params);
    },
    getActivityReport() {
      const params = {
        start_date: this.parsedStartWeek, end_date: this.parsedEndWeek, coach_id: this.coach_id, trainee_role_id: this.trainee_role_id, region_id: this.region_id, market_area_id: this.market_area_id, phase_id: this.phase_id, topic_id: this.topic_id,
      };
      return TimeCardActivityService.activityReport(params);
    },
    getUserRoleReport() {
      const params = {
        start_date: this.parsedStartWeek, end_date: this.parsedEndWeek, coach_id: this.coach_id, trainee_role_id: this.trainee_role_id, region_id: this.region_id, market_area_id: this.market_area_id, phase_id: this.phase_id,
      };
      return TimeCardActivityUserService.traineeRoleReport(params);
    },
    getTraineeReport() {
      const params = {
        start_date: this.parsedStartWeek, end_date: this.parsedEndWeek, coach_id: this.coach_id, trainee_role_id: this.trainee_role_id, region_id: this.region_id, market_area_id: this.market_area_id, phase_id: this.phase_id, export: this.export,
      };
      return TimeCardActivityUserService.traineeReport(params);
    },
    clearFilters() {
      this.coach_id = null;
      this.trainee_role_id = null;
      this.market_area_id = null;
      this.region_id = null;
      this.phase_id = null;
      this.topic_id = null;

      this.selectedCoach = null;
      this.selectedRole = null;
      this.selectedMarketArea = null;
      this.selectedRegion = null;
      this.selectedPhase = null;
      this.selectedTopic = null;
      this.updateReports();
    },
  },
  watch: {
    selectedStartWeek(val) {
      if (val) {
        this.updateReports();

        if (val !== null && this.selectedEndWeek !== null) {
          this.filtersDisabled = false;
        }
      }
    },
    selectedEndWeek(val) {
      if (val) {
        this.updateReports();

        if (val !== null && this.selectedStartWeek !== null) {
          this.filtersDisabled = false;
        }
      }
    },
    selectedCoach(val) {
      if (val) {
        this.coach_id = val.id;
        this.updateReports();
        this.trainee_role_id = null;
        this.market_area_id = null;
        this.region_id = null;
        this.phase_id = null;
        this.topic_id = null;

        this.selectedTopic = null;
        this.selectedRole = null;
        this.selectedMarketArea = null;
        this.selectedRegion = null;
        this.selectedPhase = null;
      }
    },
    selectedRole(val) {
      if (val) {
        this.trainee_role_id = val.id;
        this.updateReports();

        this.coach_id = null;
        this.market_area_id = null;
        this.region_id = null;
        this.phase_id = null;
        this.topic_id = null;

        this.selectedTopic = null;
        this.selectedCoach = null;
        this.selectedMarketArea = null;
        this.selectedRegion = null;
        this.selectedPhase = null;
      }
    },
    selectedMarketArea(val) {
      if (val) {
        this.market_area_id = val.id;
        this.updateReports();

        this.coach_id = null;
        this.trainee_role_id = null;
        this.region_id = null;
        this.phase_id = null;
        this.topic_id = null;

        this.selectedTopic = null;
        this.selectedCoach = null;
        this.selectedRole = null;
        this.selectedRegion = null;
        this.selectedPhase = null;
      }
    },
    selectedRegion(val) {
      if (val) {
        this.region_id = val.id;
        this.updateReports();

        this.coach_id = null;
        this.trainee_role_id = null;
        this.market_area_id = null;
        this.phase_id = null;
        this.topic_id = null;

        this.selectedTopic = null;
        this.selectedCoach = null;
        this.selectedRole = null;
        this.selectedMarketArea = null;
        this.selectedPhase = null;
      }
    },
    selectedPhase(val) {
      if (val) {
        this.phase_id = val.id;
        this.updateReports();

        this.coach_id = null;
        this.trainee_role_id = null;
        this.market_area_id = null;
        this.region_id = null;
        this.topic_id = null;

        this.selectedTopic = null;
        this.selectedCoach = null;
        this.selectedRole = null;
        this.selectedMarketArea = null;
        this.selectedRegion = null;
      }
    },
    selectedTopic(val) {
      if (val) {
        this.topic_id = val.id;
        this.updateReports();

        this.coach_id = null;
        this.trainee_role_id = null;
        this.market_area_id = null;
        this.region_id = null;
        this.phase_id = null;

        this.selectedPhase = null;
        this.selectedCoach = null;
        this.selectedRole = null;
        this.selectedMarketArea = null;
        this.selectedRegion = null;
      }
    },
  },
  destroyed() {
    window.removeEventListener('scroll', this.scrollCheck);
  },
};
</script>

<style lang="scss" scoped>
  .v-select.single.searching:not(.open):not(.loading) input[type=search] {
    opacity: 1;
  }

  .align-left {
    text-align: left;
  }

  .search-card {
    padding-bottom: 30px;

    .instructions {
      padding: 10px;
      border: 1px solid #dee3e7;
      border-radius: 5px;
      margin: 15px auto 15px;
    }
  }

  #topButton {
    display: none;
    position: fixed;
    bottom: 20px;
    right: 30px;
    z-index: 99;
    border: none;
    outline: none;
    color: white;
    cursor: pointer;
    padding: 10px;
    border-radius: 15px;
    font-size: 15px;

    &:hover {
      background-color: #555;
    }
  }
</style>
